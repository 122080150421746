<!-- Background -->
<div class="waldo-background">
    <!-- Gradient Header Right-->
    <div style="width: 50rem; height: 50rem; top: 47rem;left: 23%;" class="gradient-circle purple-gradient"></div>
    <div style="top: 0%; left:0%;" class="gradient-circle purple-gradient"></div>
    <div style="top: -0.5%; left:-5%;" class="gradient-circle purple-gradient"></div>
    <div style="top: -1%; left:-10%;" class="gradient-circle purple-gradient"></div>
    <div style="top: 8%; left:-20%;" class="gradient-circle purple-gradient"></div>

    <!-- Gradient Header Left-->
    <div style="width: 50rem;height: 50rem;top: 8%;left: 67%;" class="gradient-circle purple-gradient"></div>
    <div style="top: 3%; left:88%;" class="gradient-circle purple-gradient"></div>
    <div style="top: 5%; left:80%;" class="gradient-circle purple-gradient"></div>
</div>


<div class="page-content">
    <!----------------------- Header ------------------------->
    <div [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'" class="page-panel">
      <!-- Image -->
      <div class="fadePanelHeader fade-panel header-images">
        <div style="position: relative">
          <img class="img-page" style="max-width: 100%" src="assets/images/landings/waldo/header/{{currentLanguage}}/background.webp" alt="{{ 'waldo.caption.alt' | translate }}"/>
        </div>
      </div>
  
      <!-- Titre -->
      <div class="page-panel" style="margin-top: 0rem">
        <div class="page-panel-title">
          <h1 class="fadePanelHeader fade-panel" appColoredText [textColor]="'#5D5BD4'" textKey="waldo.caption.title"></h1>
          <h4 class="fadePanelHeader fade-panel">{{ "waldo.caption.description" | translate }}</h4>
        </div>
        <!-- Elue startup de l'année -->
        <div class="fadePanelHeader fade-panel waldo-tag-container">
            <div class="waldo-tag">{{"waldo.reward.text" | translate}}</div>
            <img style="max-width: 100%" src="assets/images/landings/waldo/header/microsoft-logo.svg" alt="microsoft-logo"/>
        </div>
        <!-- Bouton réserver une démo -->
        <div class="fadePanelHeader fade-panel" style="margin-top: 1rem">
            <div class="waldo-button" (click)="openUrlSelf('https://www.moffi.io/fr/demo?utm_source=waldo-ia&utm_medium=cta&utm_campaign=hero')">
                <div class="waldo-button-content">
                    {{'waldo.caption.button' | translate}}
                </div>
            </div>
        </div>
      </div>
    </div>

     <!----------------------- Clients ------------------------->
    <app-list-client
        class="client-panel"
        title="{{ 'waldo.clients.description' | translate }}"
        [clients]="clientsWaldo"
    ></app-list-client>

    <!----------------------- Waldo organise vos réunions ------------------------->
    <div class="page-panel" >
        <div class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fade-panel fadePanelMeeting page-horizontal-panel-item">
                <h2 class="waldo-gradient-title">{{'waldo.meeting.title' | translate}}</h2>
                <h3 class="page-horizontal-panel-item-text">{{'waldo.meeting.description' | translate}}</h3>
            </div>

            <!-- Image -->
            <div  [style.width]="!(isHandset$ | async) ? '34%' : '100%'"  style="height: auto; position: relative;" class="fade-panel fadePanelMeeting " [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'">
                <img src="assets/images/landings/waldo/meeting/{{currentLanguage}}/background.webp" alt="{{'waldo.meeting.alt' | translate}}"/>
            </div> 
        </div>
    </div>

    <!----------------------- Waldo s'occupe de votre agenda ------------------------->
    <div class="page-panel" >
        <!-- Version Desktop -->
        <div *ngIf="!(isHandset$ | async)" class="page-horizontal-panel" style="align-items: flex-start;">
            <!-- Image -->
            <div  [style.width]="!(isHandset$ | async) ? '34%' : '100%'"  style="height: auto; position: relative;" class="fade-panel fadePanelCalendar " [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'">
                <img src="assets/images/landings/waldo/calendar/{{currentLanguage}}/background.webp" alt="{{'waldo.calendar.alt' | translate}}"/>
            </div> 
            <!-- Texte -->
            <div class="fade-panel fadePanelCalendar page-horizontal-panel-item">
                <h2 class="waldo-gradient-title">{{'waldo.calendar.title' | translate}}</h2>
                <h3 class="page-horizontal-panel-item-text">{{'waldo.calendar.description' | translate}}</h3>
            </div>
        </div>

        <!-- Version Mobile -->
        <div *ngIf="(isHandset$ | async)" class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fade-panel fadePanelCalendar page-horizontal-panel-item">
                <h2 class="waldo-gradient-title">{{'waldo.calendar.title' | translate}}</h2>
                <h3 class="page-horizontal-panel-item-text">{{'waldo.calendar.description' | translate}}</h3>
            </div>

             <!-- Image -->
             <div class="signsImgContainer">
                <div class="signsImg fade-panel fadePanelCalendar" [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'">
                    <img src="assets/images/landings/waldo/calendar/{{currentLanguage}}/background.webp" alt="{{'waldo.calendar.alt' | translate}}"/>
                </div> 
             </div>
             
        </div>
    </div>

    <!----------------------- Waldo organise vos réunions ------------------------->
    <div class="page-panel" >
        <div class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fade-panel fadePanelBooking page-horizontal-panel-item">
                <h2 class="waldo-gradient-title">{{'waldo.booking.title' | translate}}</h2>
                <h3 class="page-horizontal-panel-item-text">{{'waldo.booking.description' | translate}}</h3>
            </div>

            <!-- Image -->
            <div  [style.width]="!(isHandset$ | async) ? '34%' : '100%'"  style="height: auto; position: relative;" class="fade-panel fadePanelBooking " [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'">
                <img src="assets/images/landings/waldo/booking/{{currentLanguage}}/background.webp" alt="{{'waldo.booking.alt' | translate}}"/>
            </div> 
        </div>
    </div>

    <!----------------------- Bien plus qu'un simple assistant ------------------------->
    <div class="page-panel">
        <!-- title -->
        <div class="page-panel-title">
          <div class="fadePanelSupport fade-panel">
            <img src="assets/images/landings/page/hands/hand_waldo_support.svg" alt="icon_hand_support"/>
            <h2 appColoredText [textColor]="'#5D5BD4'" textKey="waldo.support.title"></h2>
          </div>
          <h4 class="fadePanelSupport fade-panel">{{ "waldo.support.description" | translate }}</h4>
        </div>
  
        <div [ngClass]="!(isHandset$ | async) ? 'waldo-steps' : 'waldo-steps-mobile'" class="fadePanelSupport fade-panel">
          <app-panel-step orientation="horizontal">
            <!-- Step 1 : Point sur le projet Flex Office -->
            <app-step
              stepIconSrc="assets/images/landings/waldo/steps/step_1.svg"
              stepIconAlt="icon_step_1"
              title="{{'waldo.support.step1.title' | translate}}"
              description="{{'waldo.support.step1.description' | translate}}"
            ></app-step>
            <!-- Step 2 : Expert de l'outil -->
            <app-step
              stepIconSrc="assets/images/landings/waldo/steps/step_2.svg"
              stepIconAlt="icon_step_2"
              title="{{'waldo.support.step2.title' | translate}}"
              description="{{'waldo.support.step2.description' | translate}}"
            ></app-step>
            <!-- Step 3 : Ensuite -->
            <app-step
              stepIconSrc="assets/images/landings/waldo/steps/step_3.svg"
              stepIconAlt="icon_step_3"
              title="{{'waldo.support.step3.title' | translate}}"
              description="{{'waldo.support.step3.description' | translate}}"
            ></app-step>
          </app-panel-step>
        </div>
      </div>


    <!----------------------- Testimonies ------------------------->
    <div class="page-panel" [style]="!(isHandset$ | async) ? 'margin-bottom: 8rem;' : 'margin-bottom: 1rem;'" >
        <!-- title -->
        <div class="page-panel-title">
            <div class="fade-panel fadePanelTestimony">
                <img src="assets/images/landings/page/hands/hand_waldo_testimony.svg" alt="icon_hand_testimony"/>
                <h2 appColoredText [textColor]="'#5D5BD4'" textKey="waldo.testimony.title"></h2>
                <h4>{{ "waldo.testimony.description" | translate }}</h4>
            </div>
        </div>

        <!-- Panel cards-->
        <div [ngClass]="!(isHandset$ | async) ? 'page-panel-cards' : 'page-panel-cards-mobile'">
            <div class="fade-panel fadePanelTestimony page-panel-card">
                <!-- Prise en main rapide -->
                <app-panel-card-large 
                    iconSrc="assets/images/icon/icon_quote/quote_dark_purple.svg" 
                    iconAlt="quote_dark_purple"
                    title="{{'waldo.testimony.first.title' | translate}}"
                    text="{{'waldo.testimony.first.text' | translate}}"
                    pTitle = "{{'waldo.testimony.first.name' | translate}}"
                    borderColor="#5D5BD433"
                    imgSeparator="assets/images/landings/waldo/steps/stars.svg" 
                    linearGradientColor1="rgba(93, 91, 212, 0.2)"
                    linearGradientColor2="rgba(93, 91, 212, 0.05)"
                    boxShadowColor="#5D5BD433"></app-panel-card-large>
            </div>
            <div class="fade-panel fadePanelTestimony page-panel-card">
                <!-- Facile, fait le job -->
                <app-panel-card-large 
                    iconSrc="assets/images/icon/icon_quote/quote_dark_purple.svg" 
                    iconAlt="quote_dark_purple"
                    title="{{'waldo.testimony.second.title' | translate}}"
                    text="{{'waldo.testimony.second.text' | translate}}"
                    pTitle = "{{'waldo.testimony.second.name' | translate}}"
                    borderColor="#5D5BD433"
                    imgSeparator="assets/images/landings/waldo/steps/stars.svg" 
                    linearGradientColor1="rgba(93, 91, 212, 0.2)"
                    linearGradientColor2="rgba(93, 91, 212, 0.05)"
                    boxShadowColor="#5D5BD433"></app-panel-card-large>
            </div>
            <div class="fade-panel fadePanelTestimony page-panel-card">
                <!-- Toujours en avance -->
                <app-panel-card-large 
                    iconSrc="assets/images/icon/icon_quote/quote_dark_purple.svg" 
                    iconAlt="quote_dark_purple"
                    title="{{'waldo.testimony.third.title' | translate}}"
                    text="{{'waldo.testimony.third.text' | translate}}"
                    pTitle = "{{'waldo.testimony.third.name' | translate}}"
                    borderColor="#5D5BD433"
                    imgSeparator="assets/images/landings/waldo/steps/stars.svg" 
                    linearGradientColor1="rgba(93, 91, 212, 0.2)"
                    linearGradientColor2="rgba(93, 91, 212, 0.05)"
                    boxShadowColor="#5D5BD433"></app-panel-card-large>
            </div>
        </div>
    </div>

    <!----------------------- Bandeau démo ------------------------->
    <div style="margin-top: 0rem;" class="page-demo-panel" >

        <!-- Background -->
        <div class="page-demo-background">
            
            <div style="width: 50rem; height: 40rem; top: 8%; left:-2%;" class="gradient-circle purple-gradient"></div>
            <div style="top: -8%; left:80%;transform: rotate(140deg);opacity:0.9;" class="gradient-small-circle purple-gradient"></div>
            <div style="top: -13%; left:82%;transform: rotate(140deg); filter: blur(5rem);" class="gradient-small-circle purple-gradient"></div>
            <div style="width: 50rem; height: 40rem; top: 40%; left:65%;" class="gradient-circle purple-gradient"></div>
            <div style="top: 39%; left:81%;opacity: 0.8;" class="gradient-small-circle purple-gradient"></div>
            <div style="width: 50rem; height: 50rem; top: 76%; left:73%; opacity:0.7;" class="gradient-circle purple-gradient"></div> 
            <div style="top: -3%; left:42%;" class="gradient-small-circle pink-gradient"></div>
            <div style="top: 30%; left:56%;opacity: 0.8;" class="gradient-small-circle pink-gradient"></div>
            <div style="width: 40rem; height: 40rem;top: 2%; left:24%;" class="gradient-small-circle blue-gradient"></div>
            <div style="top: 6%; left:56%;" class="gradient-small-circle blue-gradient"></div>
            
        </div>

        <!-- title -->
        <div class="page-panel-title">
            <img src="assets/images/landings/page/hands/hand_demo.svg" alt="icon_hand_demo"/>
            <h2 class="dark-color">{{'waldo.cta.title' | translate}}</h2>
            <h4 class="dark-color" appColoredText [textColor]="'#FFFFFFBF'" textKey="waldo.cta.description"></h4>
        </div>

        <!-- Bouton démo -->
        <div style="z-index: 1;" class="waldo-button-dark" (click)="openUrlSelf('https://www.moffi.io/fr/demo?utm_source=waldo-ia&utm_medium=cta&utm_campaign=bottom')">
            <div class="waldo-button-content">{{'waldo.caption.button' | translate}}</div>
        </div>
        
        
    </div>

</div>