<div class="page-content">
  <!----------------------- Header ------------------------->
  <div [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'" class="page-panel">
    <!-- Bouton flex office -->
    <div
      [ngClass]="!(isHandset$ | async) ? '' : 'header-button-mobile'"
      class="header-button fadePanelHeader fade-panel fs-14 fw-500 secondary-color"
      (click)="openUrl('https://moffi.typeform.com/to/ftcWG0rc')"
    >
      <img src="assets/images/icon/icon_rating.svg" alt="icon_rating" />
      <span style="max-width: 90%">{{ 'home.testFlex' | translate }}</span>
      <mat-icon class="header-button-icon fs-20" fontIcon="east"></mat-icon>
    </div>
    <!-- Image -->
    <div class="fadePanelHeader fade-panel header-images">
      <div style="position: relative">
        <!-- Image Planning-->
        <div class="img-absolute fade-panel headerImage header-image header-image-booking-planning">
          <img
            style="max-width: 95%"
            src="assets/images/landings/flex/header/{{ currentLanguage }}/booking_planning.webp"
            alt="{{ 'home.hero_image.business_meeting.alt' | translate }}"
          />
        </div>
        <!-- Image Bouton Map-->
        <div class="img-absolute fade-panel headerImage header-image header-image-button-map">
          <img
            style="max-width: 95%"
            src="assets/images/landings/flex/header/{{ currentLanguage }}/button_map.webp"
            alt="{{ 'home.hero_image.desk.alt' | translate }}"
          />
        </div>
        <!-- Image Bouton Save-->
        <div class="img-absolute fade-panel headerImage header-image header-image-button-save">
          <img
            style="max-width: 95%"
            src="assets/images/landings/flex/header/{{ currentLanguage }}/button_save.webp"
            alt=""
          />
        </div>
        <!-- Image Booking Map -->
        <div class="img-absolute fade-panel headerImage header-image header-image-booking-map">
          <img
            style="max-width: 100%"
            src="assets/images/landings/flex/header/{{ currentLanguage }}/booking_map.webp"
            alt=""
          />
        </div>
        <img
          style="max-width: 100%"
          src="assets/images/landings/flex/header/{{ currentLanguage }}/background.webp"
          alt="{{ 'flex.caption.alt.planning' | translate }}"
        />
      </div>
    </div>

    <!-- Titre -->
    <div class="page-panel" style="margin-top: 0rem">
      <div class="page-panel-title">
        <h1 class="fadePanelHeader fade-panel" appColoredText [textColor]="'#074DCE'" textKey="flex.caption.title"></h1>
        <h4 class="fadePanelHeader fade-panel">
          {{ 'flex.caption.description' | translate }}
        </h4>
      </div>
      <!-- Bouton réserver une démo -->
      <div class="fadePanelHeader fade-panel" style="margin-top: 1rem">
        <moffi-ui-button
          (eventClick)="redirectTo('contact')"
          buttonType="light"
          label="{{ 'flex.caption.button' | translate }}"
        ></moffi-ui-button>
      </div>
    </div>
  </div>

  <!----------------------- Clients ------------------------->
  <app-list-client
    class="client-panel"
    title="{{ 'flex.clients.description' | translate }}"
    [clients]="clients"
  ></app-list-client>

  <!----------------------- Moffi Partenaire flex office ------------------------->
  <div class="page-panel">
    <div class="page-horizontal-panel">
      <!-- Texte -->
      <div class="fadePanelApp fade-panel page-horizontal-panel-item">
        <h2 appColoredText [textColor]="'#074DCE'" textKey="flex.app.title"></h2>
        <h3 class="page-horizontal-panel-item-text" [innerHTML]="'flex.app.description' | translate"></h3>
      </div>

      <!-- Image -->
      <div
        class="fadePanelApp fade-panel"
        [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'"
        style="position: relative"
      >
        <!-- Image Bouton-->
        <div class="fadePanelAppImg fade-panel img-absolute app-image-button">
          <img src="assets/images/landings/flex/app/{{ currentLanguage }}/button.webp" alt="" />
        </div>
        <!-- Image List User -->
        <div class="fadePanelAppImg fade-panel img-absolute app-image-user-list">
          <img src="assets/images/landings/flex/app/{{ currentLanguage }}/user_list.webp" alt="" />
        </div>
        <img
          src="assets/images/landings/flex/app/{{ currentLanguage }}/background.webp"
          alt="{{ 'flex.app.alt' | translate }}"
        />
      </div>
    </div>
  </div>

  <!----------------------- Une appli à votre image ------------------------->
  <div class="page-panel">
    <!-- Version desktop -->
    <div *ngIf="!(isHandset$ | async)" class="page-horizontal-panel" style="gap: 8rem">
      <!-- Image -->
      <div class="fadePanelCustom fade-panel page-horizontal-panel-img" style="position: relative">
        <!-- Image Color-->
        <div
          class="img-absolute custom-image-color"
          [style.backgroundImage]="'url(assets/images/landings/flex/custom/color_' + currentColor + '.webp)'"
        ></div>
        <!-- Image Mobile-->
        <div
          class="img-absolute custom-image-mobile"
          [style.backgroundImage]="
            'url(assets/images/landings/flex/custom/' + currentLanguage + '/mobile_' + currentColor + '.webp)'
          "
        ></div>
        <!-- Background -->
        <img
          src="assets/images/landings/flex/custom/{{ currentLanguage }}/background.webp"
          alt="{{ 'flex.custom.alt' | translate }}"
        />
      </div>
      <!-- Texte -->
      <div class="fadePanelCustom fade-panel page-horizontal-panel-item">
        <h2 appColoredText [textColor]="'#074DCE'" textKey="flex.custom.title"></h2>
        <h3 class="page-horizontal-panel-item-text">
          {{ 'flex.custom.description1' | translate }}
        </h3>
        <ul>
          <li>
            <mat-icon style="color: #074dce" fontIcon="done"></mat-icon>
            <h3 class="page-horizontal-panel-item-text" [innerHTML]="'flex.custom.description2' | translate"></h3>
          </li>
          <li>
            <mat-icon style="color: #074dce" fontIcon="done"></mat-icon>
            <h3 class="page-horizontal-panel-item-text">{{ 'flex.custom.description3' | translate }}</h3>
          </li>
          <li>
            <mat-icon style="color: #074dce" fontIcon="done"></mat-icon>
            <h3 class="page-horizontal-panel-item-text">{{ 'flex.custom.description4' | translate }}</h3>
          </li>
        </ul>
      </div>
    </div>
    <!-- Version mobile -->
    <div *ngIf="isHandset$ | async" class="page-horizontal-panel">
      <!-- Texte -->
      <div class="fadePanelCustom fade-panel page-horizontal-panel-item">
        <h2 appColoredText [textColor]="'#074DCE'" textKey="flex.custom.title"></h2>
        <h3 class="page-horizontal-panel-item-text">{{ 'flex.custom.description1' | translate }}</h3>
        <ul>
          <li>
            <mat-icon style="color: #074dce" fontIcon="done"></mat-icon>
            <h3 class="page-horizontal-panel-item-text" [innerHTML]="'flex.custom.description2' | translate"></h3>
          </li>
          <li>
            <mat-icon style="color: #074dce" fontIcon="done"></mat-icon>
            <h3 class="page-horizontal-panel-item-text">{{ 'flex.custom.description3' | translate }}</h3>
          </li>
          <li>
            <mat-icon style="color: #074dce" fontIcon="done"></mat-icon>
            <h3 class="page-horizontal-panel-item-text">{{ 'flex.custom.description4' | translate }}</h3>
          </li>
        </ul>
      </div>

      <!-- Image -->
      <div class="fadePanelCustom fade-panel page-horizontal-panel-img" style="position: relative">
        <!-- Image Color-->
        <div class="img-absolute custom-image-color-small">
          <img src="assets/images/landings/flex/custom/color_{{ currentColor }}.webp" alt="" />
        </div>
        <!-- Image Mobile-->
        <div class="img-absolute custom-image-mobile-small">
          <img src="assets/images/landings/flex/custom/{{ currentLanguage }}/mobile_{{ currentColor }}.webp" alt="" />
        </div>
        <img
          src="assets/images/landings/flex/custom/{{ currentLanguage }}/background.webp"
          alt="{{ 'flex.custom.alt' | translate }}"
        />
      </div>
    </div>
  </div>

  <!----------------------- La donnée au coeur de votre organisation ------------------------->
  <div class="page-panel">
    <div class="page-horizontal-panel">
      <!-- Texte -->
      <div class="fadePanelData fade-panel page-horizontal-panel-item">
        <h2 appColoredText [textColor]="'#074DCE'" textKey="flex.data.title"></h2>
        <h3 class="page-horizontal-panel-item-text">{{ 'flex.data.description1' | translate }}</h3>
        <ul>
          <li>
            <mat-icon style="color: #074dce" fontIcon="done"></mat-icon>
            <h3 class="page-horizontal-panel-item-text" [innerHTML]="'flex.data.description2' | translate"></h3>
          </li>
          <li>
            <mat-icon style="color: #074dce" fontIcon="done"></mat-icon>
            <h3 class="page-horizontal-panel-item-text" [innerHTML]="'flex.data.description3' | translate"></h3>
          </li>
          <li>
            <mat-icon style="color: #074dce" fontIcon="done"></mat-icon>
            <h3 class="page-horizontal-panel-item-text">{{ 'flex.data.description4' | translate }}</h3>
          </li>
        </ul>
      </div>

      <!-- Image -->
      <div
        class="fadePanelData fade-panel"
        [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'"
        style="position: relative"
      >
        <!-- Image Impact-->
        <div class="fadePanelDataImg fade-panel img-absolute data-image-declarations">
          <img src="assets/images/landings/flex/data/{{ currentLanguage }}/declarations.webp" alt="" />
        </div>
        <!-- Image Rule-->
        <div class="fadePanelDataImg fade-panel img-absolute data-image-spaces">
          <img src="assets/images/landings/flex/data/{{ currentLanguage }}/spaces.webp" alt="" />
        </div>
        <!-- Image Rule-->
        <div class="fadePanelDataImg fade-panel img-absolute data-image-button-export">
          <img src="assets/images/landings/flex/data/{{ currentLanguage }}/button_export.webp" alt="" />
        </div>
        <img
          src="assets/images/landings/flex/data/{{ currentLanguage }}/background.webp"
          alt="{{ 'flex.data.alt' | translate }}"
        />
      </div>
    </div>
  </div>

  <!----------------------- Le flex office ? Mieux avec Moffi ------------------------->
  <div class="page-panel">
    <!-- title -->
    <div class="page-panel-title">
      <div class="fadePanelAssets fade-panel">
        <img src="assets/images/landings/page/hands/hand_assets.svg" alt="icon_hand_flex" />
        <h2 appColoredText [textColor]="'#074DCE'" textKey="flex.assets.title"></h2>
      </div>
    </div>
    <!-- Tableau Version Desktop -->
    <div *ngIf="!(isHandset$ | async)" class="fadePanelAssets fade-panel" style="margin-top: 3rem; max-width: 95%">
      <app-simple-table [data]="assetsTableData">
        <!-- Header -->
        <ng-template let-item #headerTemplate>
          <tr>
            <th class="assets-table-header" id="asset"></th>
            <th class="assets-table-header assets-table-border-left" id="withoutMoffi">
              {{ 'flex.assets.without_moffi.title' | translate }}
            </th>
            <th class="assets-table-header assets-table-border-left" id="withMoffi">
              {{ 'flex.assets.with_moffi.title' | translate }}
            </th>
          </tr>
        </ng-template>
        <!-- Colonnes -->
        <ng-template let-item #columnTemplate>
          <td
            class="assets-table-td assets-table-td-asset"
            appColoredText
            [textColor]="'#074DCE'"
            [textKey]="item.assetKey"
          ></td>
          <td class="assets-table-td assets-table-border-left">
            <mat-icon *ngIf="!item.withoutMoffi" style="color: #c5cbec" fontIcon="close"></mat-icon>
            <mat-icon *ngIf="item.withoutMoffi" style="color: #45c6aa" fontIcon="done"></mat-icon>
          </td>
          <td class="assets-table-td assets-table-border-left">
            <mat-icon *ngIf="!item.withMoffi" style="color: #c5cbec" fontIcon="close"></mat-icon>
            <mat-icon *ngIf="item.withMoffi" style="color: #45c6aa" fontIcon="done"></mat-icon>
          </td>
        </ng-template>
      </app-simple-table>
    </div>
    <!-- Tableau Version Mobile -->
    <div *ngIf="isHandset$ | async" class="fadePanelAssets fade-panel" style="margin-top: 3rem; max-width: 95%">
      <app-simple-table [padding]="'1rem'" [data]="assetsTableDataMobile">
        <!-- Header -->
        <ng-template let-item #headerTemplate>
          <tr>
            <th class="assets-table-header-mobile" id="withoutMoffi">
              {{ 'remote.assets.without_moffi.title' | translate }}
            </th>
            <th class="assets-table-header-mobile" id="withMoffi">
              {{ 'remote.assets.with_moffi.title' | translate }}
            </th>
          </tr>
        </ng-template>
        <!-- Colonnes -->
        <ng-template let-item #columnTemplate>
          <td
            *ngIf="item.assetKey"
            colspan="2"
            class="assets-table-td-mobile"
            appColoredText
            [textColor]="'#074DCE'"
            [textKey]="item.assetKey"
          ></td>

          <td
            *ngIf="!item.assetKey"
            [ngClass]="!item.isLastElement ? 'assets-table-border-bottom' : ''"
            class="assets-table-td-mobile"
          >
            <mat-icon *ngIf="!item.withoutMoffi" style="color: #c5cbec" fontIcon="close"></mat-icon>
            <mat-icon *ngIf="item.withoutMoffi" style="color: #45c6aa" fontIcon="done"></mat-icon>
          </td>
          <td
            *ngIf="!item.assetKey"
            [ngClass]="!item.isLastElement ? 'assets-table-border-bottom' : ''"
            class="assets-table-td-mobile"
          >
            <mat-icon *ngIf="!item.withMoffi" style="color: #c5cbec" fontIcon="close"></mat-icon>
            <mat-icon *ngIf="item.withMoffi" style="color: #45c6aa" fontIcon="done"></mat-icon>
          </td>
        </ng-template>
      </app-simple-table>
    </div>
    <!-- Bouton J'en profite -->
    <div class="fadePanelAssets fade-panel" style="margin-top: 2rem">
      <moffi-ui-button
        (eventClick)="redirectTo('contact')"
        buttonType="light"
        label="{{ 'flex.assets.button' | translate }}"
      ></moffi-ui-button>
    </div>
  </div>

  <!----------------------- Offrez à vos salariés le meilleur de l'organisation de travail ------------------------->
  <div class="flex-background-bottom flex-panel-large page-content" style="margin-top: 3rem">
    <div class="page-panel">
      <!-- Version desktop -->
      <div *ngIf="!(isHandset$ | async)" class="page-horizontal-panel">
        <!-- Texte -->
        <div class="fadePanelHybrid fade-panel page-horizontal-panel-item">
          <h2 appColoredText [textColor]="'#074DCE'" textKey="flex.hybrid.title"></h2>
          <h3
            class="page-horizontal-panel-item-text third-color"
            [innerHTML]="'flex.hybrid.description' | translate"
          ></h3>
        </div>
        <!-- Image -->
        <div class="fadePanelHybrid fade-panel page-horizontal-panel-img" style="position: relative">
          <!-- Image Impact-->
          <div class="fadePanelHybridImg fade-panel img-absolute hybrid-image-icons">
            <img src="assets/images/landings/flex/hybrid/{{ currentLanguage }}/icons.webp" alt="" />
          </div>
          <img
            style="max-width: 100%"
            src="assets/images/landings/flex/hybrid/{{ currentLanguage }}/background.webp"
            alt="{{ 'flex.hybrid.alt' | translate }}"
          />
          <p
            class="hybrid-description-image primary-color"
            appColoredText
            [textColor]="'#074DCE'"
            textKey="flex.hybrid.image.description"
          ></p>
        </div>
      </div>
      <!-- Version mobile -->
      <div *ngIf="isHandset$ | async" class="page-horizontal-panel">
        <!-- Image -->
        <div class="fadePanelHybrid fade-panel page-horizontal-panel-img" style="position: relative">
          <!-- Image Impact-->
          <div class="fadePanelHybridImg fade-panel img-absolute hybrid-image-icons">
            <img src="assets/images/landings/flex/hybrid/{{ currentLanguage }}/icons.webp" alt="" />
          </div>
          <img
            src="assets/images/landings/flex/hybrid/{{ currentLanguage }}/background.webp"
            alt="{{ 'flex.hybrid.alt' | translate }}"
          />
          <p
            class="hybrid-description-image-mobile primary-color"
            appColoredText
            [textColor]="'#074DCE'"
            textKey="flex.hybrid.image.description"
          ></p>
        </div>
        <!-- Texte -->
        <div class="fadePanelHybrid fade-panel page-horizontal-panel-item">
          <h2 appColoredText [textColor]="'#074DCE'" textKey="flex.hybrid.title"></h2>
          <h3
            class="page-horizontal-panel-item-text third-color"
            [innerHTML]="'flex.hybrid.description' | translate"
          ></h3>
        </div>
      </div>
    </div>

    <!----------------------- Accompagnement ------------------------->
    <div class="page-panel">
      <!-- title -->
      <div class="page-panel-title">
        <div class="fadePanelSupport fade-panel">
          <img src="assets/images/landings/page/hands/hand_support.svg" alt="icon_hand_support" />
          <h2 appColoredText [textColor]="'#074DCE'" textKey="flex.support.title"></h2>
        </div>
        <h4 class="fadePanelSupport fade-panel">
          {{ 'flex.support.description' | translate }}
        </h4>
      </div>

      <div [ngClass]="!(isHandset$ | async) ? 'flex-steps' : 'flex-steps-mobile'" class="fadePanelSupport fade-panel">
        <app-panel-step orientation="horizontal">
          <!-- Step 1 : Point sur le projet Flex Office -->
          <app-step
            stepIconSrc="assets/images/landings/page/steps/screen.svg"
            stepIconAlt="icon_screen"
            title="{{ 'flex.support.step1.title' | translate }}"
            description="{{ 'flex.support.step1.description' | translate }}"
          ></app-step>
          <!-- Step 2 : Expert de l'outil -->
          <app-step
            stepIconSrc="assets/images/landings/page/steps/hat.svg"
            stepIconAlt="icon_hat"
            title="{{ 'flex.support.step2.title' | translate }}"
            description="{{ 'flex.support.step2.description' | translate }}"
          ></app-step>
          <!-- Step 3 : Ensuite -->
          <app-step
            stepIconSrc="assets/images/landings/page/steps/notes.svg"
            stepIconAlt="icon_notes"
            title="{{ 'flex.support.step3.title' | translate }}"
            description="{{ 'flex.support.step3.description' | translate }}"
          ></app-step>
        </app-panel-step>
      </div>
    </div>

    <!----------------------- Ambasadeurs ------------------------->
    <div class="page-panel" [style]="!(isHandset$ | async) ? 'margin-bottom: 8rem;' : 'margin-bottom: 1rem;'">
      <!-- title -->
      <div class="page-panel-title">
        <div class="fadePanelTestimony fade-panel">
          <img src="assets/images/landings/page/hands/hand_testimony.svg" alt="icon_hand_testimony" />
          <h2 appColoredText [textColor]="'#074DCE'" textKey="flex.testimony.title"></h2>
        </div>
        <h4 class="fadePanelTestimony fade-panel">
          {{ 'flex.testimony.description' | translate }}
        </h4>
      </div>

      <!-- Panel cards-->
      <div [ngClass]="!(isHandset$ | async) ? 'page-panel-cards' : 'page-panel-cards-mobile'">
        <div class="fadePanelTestimony page-panel-card fade-panel">
          <!-- Citeo -->
          <app-panel-card-large
            iconSrc="assets/images/icon/icon_quote/quote_cyan.svg"
            iconAlt="quote_cyan"
            text="{{ 'flex.testimony.first.text' | translate }}"
            imgSrc="assets/images/illustrations/testimonies/show_room.webp"
            imgAlt="Citeo"
            pTitle="{{ 'flex.testimony.first.name' | translate }}"
            pText="{{ 'flex.testimony.first.position' | translate }}"
            borderColor="#94ECFF"
            linearGradientColor1="#E4FAFF"
            linearGradientColor2="rgba(228, 250, 255, 0.25)"
            boxShadowColor="rgba(69, 198, 170, 0.20)"
            linkColor="#00ACD1"
          ></app-panel-card-large>
        </div>
        <div class="fadePanelTestimony page-panel-card fade-panel">
          <!-- Effy -->
          <app-panel-card-large
            iconSrc="assets/images/icon/icon_quote/quote_purple.svg"
            iconAlt="quote_purple"
            text="{{ 'flex.testimony.second.text' | translate }}"
            imgSrc="assets/images/illustrations/testimonies/santevet.webp"
            imgAlt="Effy"
            pTitle="{{ 'flex.testimony.second.name' | translate }}"
            pText="{{ 'flex.testimony.second.position' | translate }}"
            borderColor="#B494FF"
            linearGradientColor1="#EFEBFC"
            linearGradientColor2="rgba(239, 235, 252, 0.25)"
            boxShadowColor="rgba(180, 148, 255, 0.20)"
            linkColor="#7E47FF"
          ></app-panel-card-large>
        </div>
        <div class="fadePanelTestimony page-panel-card fade-panel">
          <!-- Le Village -->
          <app-panel-card-large
            iconSrc="assets/images/icon/icon_quote/quote_pink.svg"
            iconAlt="quote_pink"
            text="{{ 'flex.testimony.third.text' | translate }}"
            imgSrc="assets/images/illustrations/testimonies/cyrillus.webp"
            imgAlt="Le Village"
            pTitle="{{ 'flex.testimony.third.name' | translate }}"
            pText="{{ 'flex.testimony.third.position' | translate }}"
            borderColor="#FF94F4"
            linearGradientColor1="#FFECFD"
            linearGradientColor2="rgb(255, 236, 253, 0.25)"
            boxShadowColor="rgba(255, 148, 244, 0.20)"
            linkColor="#CB62C0"
          ></app-panel-card-large>
        </div>
      </div>
    </div>
  </div>

  <!----------------------- Bandeau démo ------------------------->
  <div style="margin-top: 0rem" class="page-demo-panel">
    <!-- Background -->
    <div class="page-demo-background">
      <div style="width: 50rem; height: 40rem; top: -61%; left: 76%" class="gradient-circle blue-gradient"></div>
      <div
        style="top: -8%; left: 80%; transform: rotate(140deg); opacity: 0.9"
        class="gradient-small-circle pink-gradient"
      ></div>
      <div
        style="top: -13%; left: 82%; transform: rotate(140deg); filter: blur(5rem)"
        class="gradient-small-circle yellow-gradient"
      ></div>
      <div style="width: 50rem; height: 40rem; top: 40%; left: 65%" class="gradient-circle blue-gradient"></div>
      <div style="top: 39%; left: 81%; opacity: 0.8" class="gradient-small-circle green-gradient"></div>
      <div
        style="width: 50rem; height: 50rem; top: 76%; left: 73%; opacity: 0.7"
        class="gradient-circle purple-gradient"
      ></div>
    </div>

    <!-- title -->
    <div class="page-panel-title">
      <img src="assets/images/landings/page/hands/hand_demo.svg" alt="icon_hand_demo" />
      <h2 class="dark-color">{{ 'flex.cta.title' | translate }}</h2>
      <h4 class="dark-color" appColoredText [textColor]="'#7285D8'" textKey="flex.cta.description"></h4>
    </div>

    <!-- Bouton démo -->
    <moffi-ui-button
      (eventClick)="redirectTo('contact')"
      buttonType="dark"
      label="{{ 'flex.cta.button' | translate }}"
    ></moffi-ui-button>
  </div>
</div>
